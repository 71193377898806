<script>
	import gapStyles from '$lib/styles/gap.module.css';
	import paddingStyles from '$lib/styles/padding.module.css';

	export let as = 'div';

	let className = '';
	export { className as class };

	/** @type {'left' | 'center' | 'right' | 'justify' | undefined} */
	export let align = undefined;

	/** @type {'top' | 'middle' | 'bottom' | 'baseline' | undefined} */
	export let valign = undefined;

	/** @type {Gap | undefined} */
	export let gap = undefined;

	/** @type {true | number | undefined} */
	export let grow = undefined;

	/** @type {Padding | undefined} */
	export let padding = undefined;

	/** @type {Padding | undefined} */
	export let paddingTop = undefined;
	/** @type {Padding | undefined} */
	export let paddingBottom = undefined;
	/** @type {Padding | undefined} */
	export let paddingY = undefined;

	/** @type {Padding | undefined} */
	export let paddingLeft = undefined;
	/** @type {Padding | undefined} */
	export let paddingRight = undefined;
	/** @type {Padding | undefined} */
	export let paddingX = undefined;

	/** @type {boolean | undefined} */
	export let wrap = undefined;
</script>

<svelte:element
	this={as}
	class="flex {gapStyles.gap} {paddingStyles.padding} {className}"
	data-align={align}
	data-gap={gap}
	data-padding={padding}
	data-padding-bottom={paddingBottom ?? paddingY}
	data-padding-top={paddingTop ?? paddingY}
	data-padding-right={paddingRight ?? paddingX}
	data-padding-left={paddingLeft ?? paddingX}
	data-valign={valign}
	style:flex-grow={grow === true ? 1 : grow}
	style:flex-wrap={wrap === true ? 'wrap' : undefined}
	{...$$restProps}><slot /></svelte:element
>

<style>
	.flex {
		display: flex;
	}

	.flex[data-align='left'] {
		justify-content: flex-start;
	}
	.flex[data-align='center'] {
		justify-content: center;
	}
	.flex[data-align='right'] {
		justify-content: flex-end;
	}
	.flex[data-align='justify'] {
		justify-content: space-evenly;
	}

	.flex[data-valign='top'] {
		align-items: flex-start;
	}
	.flex[data-valign='middle'] {
		align-items: center;
	}
	.flex[data-valign='bottom'] {
		align-items: flex-end;
	}
	.flex[data-valign='baseline'] {
		align-items: baseline;
	}
</style>
